import { useTranslation } from 'react-i18next'
import PartenersLogo from './PartenersLogo'
import Line from '../../general/Line'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

//Partners Logo
import Devtagon from '../../../assets/partners/Devtagon_250x125_decuped.png'
import CoinAjans from '../../../assets/partners/CoinAjans_250x125_decuped.png'
import KoinMedya from '../../../assets/partners/Koinmedya-_250x125_decuped.png'
import KriptoPara from '../../../assets/partners/Kriptoparahaber_250x125_whitebg-1.png'
import alphaCapital from '../../../assets/partners/AlphaCapital.jpeg'

import { useWindowWidth } from '../../utils/useWindowWidth'

const Partners = () => {
  const innerWidth = useWindowWidth()
  const slides = () => {
    if (innerWidth >= 1200) {
      return 4
    }
    if (innerWidth >= 812) {
      return 3
    } else {
      return 2
    }
  }

  const { t } = useTranslation()
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: slides(),
    slidesToScroll: 1,
    arrows: false,
  }
  return (
    <section className='partners'>
      <h3>{t('translation:strategicPartners')}</h3>
      <Line />

      <div className='container'>
        <Slider {...settings}>
          <PartenersLogo img={Devtagon} link='https://devtagon.com' />
          <PartenersLogo img={CoinAjans} link='https://coinajans.com/' />
          <PartenersLogo img={KoinMedya} link='https://koinmedya.com/' />
          <PartenersLogo img={KriptoPara} link='https://kriptoparahaber.com/' />
          <PartenersLogo
            img={alphaCapital}
            link='https://www.alphacapital.financial/'
          />
        </Slider>
      </div>
    </section>
  )
}

export default Partners
