import { useTranslation } from 'react-i18next'
import HomeIntro from '../../../assets/gifs/Home-Intro.gif'
import IntroButtons from './IntroButtons'
const Introduction = () => {
  const { t } = useTranslation()
  return (
    <section id='home' className='introduction'>
      <div className='introText'>
        <div className='introHead'>
          <h1>{t('translation:intro-header')}</h1>
          <p>{t('translation:headerParagraph')}</p>
          <p className='marginZero'>{t('translation:headerParagraph2')}</p>
        </div>
        <IntroButtons />
      </div>
      <div className='introImg'>
        <img src={HomeIntro} alt='promotion_marketplace_image' />
      </div>
    </section>
  )
}

export default Introduction
