import { useTranslation } from 'react-i18next'
import Line from '../../general/Line'
import BuyNowButton from './BuyNowButton'
const UpcomingFundraise = () => {
  const { t } = useTranslation()
  return (
    <div className='fundraise'>
      <div className='fundraiseHead'>
        <h4>{t('translation:upcomming')}</h4>
        <Line />
      </div>
      <div className='fundraiseCards'>

        <div className='card'>
          <div className='cardHead'>
            <h5>{t('translation:presale')}</h5>
            <p>{t('translation:onlyInvestorProfile')}</p>
          </div>
          <div className='cardContent'>
            <h6>1 PROMO = 0,015 USD</h6>
            <p>
              <span>{t('translation:tokenAllocation')}: 2%</span>
            </p>
            <p>
              <span>{t('translation:tokenForSale')}: 18.000.000 PROMO</span>
            </p>
            <p>
              <span>Total fundraise: 270.000 USD</span>
            </p>
            <p>
              <span>{t('translation:minMax')}: 1.000/25.000 USD</span>
            </p>
            <p>Date: Started</p>
          </div>
          <BuyNowButton to={"https://forms.gle/qFNSjgN2UFqqqg6f9"} />
        </div>
        <div className='card'>
          <div className='cardHead'>
            <h5>{t('translation:strategicRound')}</h5>
            <p>{t('translation:onlyStrategicInvestors')}</p>
          </div>
          <div className='cardContent'>
            <h6>1 PROMO = 0,015 USD</h6>
            <p>
              <span>{t('translation:tokenAllocation')}: 6%</span>
            </p>
            <p>
              <span>{t('translation:tokenForSale')}: 54.000.000 PROMO</span>
            </p>
            <p>
              <span>Total fundraise: 810.000 USD</span>
            </p>
            <p>
              <span>{t('translation:minMax')}: 25.000/250.000 USD</span>
            </p>
            <p>Date: Started</p>
          </div>
          <BuyNowButton to={"https://forms.gle/qFNSjgN2UFqqqg6f9"} />
        </div>
        <div className='card'>
          <div className='cardHead'>
            <h5>{t('translation:privateSale')}</h5>
            <p>{t('translation:onlyInfluencerProfile')}</p>
          </div>
          <div className='cardContent'>
            <h6>1 PROMO = 0,020 USD</h6>
            <p>
              <span>{t('translation:tokenAllocation')}: 1%</span>
            </p>
            <p>
              <span>{t('translation:tokenForSale')}: 9.000.000 PROMO</span>
            </p>
            <p>
              <span>Total fundraise: 180.000 USD</span>
            </p>
            <p>
              <span>{t('translation:minMax')}: 250/2.500 USD</span>
            </p>
            <p>Date: TBA</p>
          </div>
          <BuyNowButton to="https://app.promodex.io/sale?id=5&status=Delayed&idoname=Private%20Sale&idoType=Private%20Sale" />
        </div>
        <div className='card'>
          <div className='cardHead'>
            <h5>{t('translation:publicSale')}</h5>
            <p>{t('translation:publicInvestors')}</p>
          </div>
          <div className='cardContent'>
            <h6>1 PROMO = 0,025 USD</h6>
            <p>
              <span>{t('translation:tokenAllocation')}: 4%</span>
            </p>
            <p>
              <span>{t('translation:tokenForSale')}: 36.000.000 PROMO</span>
            </p>
            <p>
              <span>Total fundraise: 900.000 USD</span>
            </p>
            <p>
              <span>{t('translation:minMax')}: <br/>0/1.000 USD</span>
            </p>
            <p>Date: TBA</p>
          </div>
          <BuyNowButton to="https://app.promodex.io/" />
        </div>
      </div>
    </div>
  )
}

export default UpcomingFundraise
