//Photos
import Ferudun_Yilmaz from '../../../assets/persons/Ferudun_Yilmaz.png'
import Mikail_Gokmen from '../../../assets/persons/Mikail_Gokmen.png'
import Serkan_Ugur from '../../../assets/persons/Serkan_Ugur.png'
import Hakan_Aysel from '../../../assets/persons/Hakan_Aysel.jpeg'
import Durmus_Pala from '../../../assets/persons/Durmus_Pala.jpeg'
import Hakan_Unal from '../../../assets/persons/Hakan_Unal.jpeg'
import Ilyas_Gokcek from '../../../assets/persons/Ilyas_Gokcek.jpeg'
import MehmetZiya_Yazgan from '../../../assets/persons/MehmetZiya_Yazgan.jpeg'
import Bunyamin_Emec from '../../../assets/persons/Bunyamin_Emec.jpeg'

//Components
import Profile from './Profile'
import Line from '../../general/Line'
import { useTranslation } from 'react-i18next'

const Team = () => {
  const { t } = useTranslation()
  return (
    <section id='team' className='team'>
      <h3>{t('translation:ourMotivatedTeam')}</h3>
      <Line />
      <div className='profiles'>
        <Profile
          name={'Mikail Gökmen'}
          titr={'CEO, Founder'}
          linkdn={'https://www.linkedin.com/in/mikail-g%C3%B6kmen-68469753/'}
          photoURI={Mikail_Gokmen}
        />
        <Profile
          name={'Feridun Yılmaz'}
          titr={'CNO, Cofounder'}
          linkdn={'https://www.linkedin.com/in/feridun-y%C4%B1lmaz-24105112b/'}
          photoURI={Ferudun_Yilmaz}
        />
        <Profile
          name={'Serkan Uğur'}
          titr={'CMO, Cofounder'}
          linkdn={'https://www.linkedin.com/in/serkan-u%C4%9Fur-9b011743'}
          photoURI={Serkan_Ugur}
        />
        <Profile
          name={'Hakan Aysel'}
          titr={'CTO, Cofounder'}
          linkdn={'https://www.linkedin.com/in/hakan-aysel-58398516/'}
          photoURI={Hakan_Aysel}
        />
        <Profile
          name={'Durmuş Pala'}
          titr={'Software Engineer'}
          linkdn={'https://www.linkedin.com/in/durmus-pala/'}
          photoURI={Durmus_Pala}
        />
        <Profile
          name={'Hakan Ünal'}
          titr={'Software Engineer'}
          linkdn={'https://www.linkedin.com/in/hakan-unal/'}
          photoURI={Hakan_Unal}
        />
        <Profile
          name={'İlyas Gökçek'}
          titr={'Software Engineer'}
          linkdn={'https://www.linkedin.com/in/ilyasgokcek'}
          photoURI={Ilyas_Gokcek}
        />
        <Profile
          name={'Mehmet Ziya Yazgan'}
          titr={'Software Engineer'}
          linkdn={'https://www.linkedin.com/in/mzyazgan/'}
          photoURI={MehmetZiya_Yazgan}
        />
        <Profile
          name={'Bünyamin Emeç'}
          titr={'Business Development'}
          linkdn={'https://www.linkedin.com/in/b%C3%BCnyamin-eme%C3%A7/'}
          photoURI={Bunyamin_Emec}
        />
      </div>
    </section>
  )
}

export default Team
