const PartenersLogo = ({ img, link }) => {
  return (
    <div className='image-wrapper'>
      <a href={link} target='_blank' rel='noreferrer'>
        <img src={img} alt='partners logo' />
      </a>
    </div>
  )
}

export default PartenersLogo
