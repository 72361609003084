import React from 'react'

function BuyNowButton({ to }) {
  return (
    <a href={to} target='_blank' style={{
      width: 150,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      bottom: -20,
      left: 'calc(50% - 75px)',
      color: '#FFFFFF',
      backgroundColor: '#574cc6',
      border: '1px solid #FFFFFF',
      borderRadius: '4px'
    }} rel="noreferrer">Buy Now</a>
  )
}

export default BuyNowButton