import { useState } from 'react'
import AboutGif from '../../../assets/gifs/About.gif'
import { IoMdPlay } from 'react-icons/io'
import { AiOutlineCloseSquare } from 'react-icons/ai'
import { BsArrowRightShort } from 'react-icons/bs'
import FrameVideo from '../introduction/FrameVideo'
import { useTranslation } from 'react-i18next'
import Line from '../../general/Line'

const WhatIsPromodex = () => {
  const { t } = useTranslation()
  const [showVideo, setShowVideo] = useState(false)
  return (
    <div className='whatIs'>
      <div className='aboutVideo'>
        <span className='aboutPlay' onClick={() => setShowVideo(true)}>
          <IoMdPlay />
        </span>

        <div className='aboutImg'>
          <img src={AboutGif} alt='what_is_promodex' />
        </div>
        {showVideo && (
          <div className='frame'>
            <AiOutlineCloseSquare onClick={() => setShowVideo(false)} />
            <FrameVideo />
          </div>
        )}
      </div>
      <div className='whatIsContent'>
        <h3 className='whatIsHeader'>{t('translation:whatIsPromodexTitle')}</h3>
        <span>{t('translation:whatIsPromodexSmall')}</span>
        <Line />
        <h6 className='whatIsSubHeader'>
          {t('translation:whatIsPromodexParagraph1A')}
          <span className='marketplaceLink'>
            <a href='https://app.promodex.io/'>
              {t('translation:whatIsPromodexParagraphLink')}
            </a>
          </span>
          {t('translation:whatIsPromodexParagraph1B')}
        </h6>

        <p className='blockquote'>{t('translation:whatIsPromodexCardText')}</p>

        <p className='whatIsParagraph'>
          {t('translation:whatIsPromodexParagraph2')}
        </p>
        <p className='whatIsParagraph'>
          {t('translation:whatIsPromodexParagraph3')}
        </p>
        <div className='aboutListBox'>
          <ul>
            <li>
              <BsArrowRightShort className='arrowIcon' />
              {t('translation:whatIsPromodexListItem1')}
            </li>
            <li>
              <BsArrowRightShort className='arrowIcon' />
              {t('translation:whatIsPromodexListItem2')}
            </li>
            <li>
              <BsArrowRightShort className='arrowIcon' />
              {t('translation:whatIsPromodexListItem3')}
            </li>
          </ul>
          <ul>
            <li>
              <BsArrowRightShort className='arrowIcon' />
              {t('translation:whatIsPromodexListItem4')}
            </li>
            <li>
              <BsArrowRightShort className='arrowIcon' />
              {t('translation:whatIsPromodexListItem5')}
            </li>
            <li>
              <BsArrowRightShort className='arrowIcon' />
              {t('translation:whatIsPromodexListItem6')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default WhatIsPromodex
